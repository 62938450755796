<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="80%" @click:outside="clickOutside">
      <template v-slot:activator="{ on, attrs }">
        <p id="movementHistory" v-bind="attrs" v-on="on" @click="detail"></p>
      </template>
      <v-container
        style="background: white; border-radius: 5px; position: relative;"
        v-if="result !== null"
      >
        <v-card
          flat
          style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
        >
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 20px; margin: 0; font-weight: bold">
                Aset movement
              </p>
            </v-toolbar-title>
          </div>
          <div>
            <!-- <v-btn tile color="black" class="ma-2" style="color: white">
              Terima
            </v-btn>
            <v-btn tile color="black" class="ma-2" style="color: white">
              Cancel
            </v-btn> -->
            <v-btn
              @click="close"
              tile
              color="black"
              class="ma-2"
              style="color: white"
            >
              Tutup
            </v-btn>
          </div>
        </v-card>

        <!-- <v-row style="padding: 15px; margin-top:10px;">
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  No. Pemindahan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense readonly style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Tgl. Terima
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense readonly style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="6" style="padding: 0"> </v-col>
        </v-row> -->
        <v-row style="padding: 15px; margin-top:10px;">
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Nama Aset
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Kode Produk
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Kode Aset
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Lot Terpinjam
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
        </v-row>
        <hr />
        <div>
          <!-- <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 16px; margin: 0; font-weight: bold">
                Data Pemindahan
              </p>
            </v-toolbar-title>
          </div> -->
          <!-- <v-row style="padding: 15px; margin-top:10px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    No. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tgl. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Destinasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Total Item
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <v-row style="padding: 15px; margin-top:10px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    PIC
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Plant
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Department
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Email
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
          </v-row> -->

          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0 10px">
              <v-card
                flat
                style="
            top: 0;
            background: white;
            margin-top: 20px;
            margin-bottom: 40px;"
              >
                <v-data-table
                  :headers="headers"
                  class="elevation-1 mx-auto"
                  style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0"
                  :items-per-page="10"
                  hide-default-footer
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Movement-Detail',
  props: ['result', 'permission'],
  data() {
    return {
      dialog: false,
      form: {},
      loading: false,

      headers: [
        {
          text: 'No. Lot',
          value: 'lot_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jumlah',
          value: 'total',
          align: 'left',
          sortable: false
        },
        {
          text: 'Dipinjam dari',
          value: 'loan_from',
          align: 'left',
          sortable: false
        },
        {
          text: 'Dipinjam oleh',
          value: 'loan_by',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jml. Peminjaman',
          value: 'loan_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lokasi Terakhir',
          value: 'last_location',
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  // created() {
  // },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapMutations([]),
    detail() {
      console.log('detail aset')
    },
    clickOutside() {
      this.$emit('close')
    },
    close() {
      this.$emit('close')
      this.dialog = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
